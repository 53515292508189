import './style.css';

import landingBgImg from './metadata/memes/welcome-landing-home-bg.jpg';
import singleBellMp3 from './metadata/sounds/sound-effect-doorbell-rings-single.mp3';
import landing_home_gif from './metadata/memes/welcome-landing-home.gif';
import footsteps_mp3 from './metadata/sounds/concrete-footsteps-trimmed.mp3';
import openDoor_mp3 from './metadata/sounds/open-door.mp3';

import og_flip_gif from './metadata/memes/f3e3vLxB7TOuIxDVrX.webp';
import og_flip_inverted_gif from './metadata/memes/f3e3vLxB7TOuIxDVrX-ezgif.com-rotate.webp';
import head_flip_small_gif from './metadata/memes/ricardo-ricardo-flick-small.gif';
import head_flip_small_inverted_gif from './metadata/memes/ricardo-ricardo-flick-small-ezgif.com-rotate.gif';
import chest_dance_1_gif from './metadata/memes/chest-weird-flex-but-ok-flex.gif';
import chest_dance_2_gif from './metadata/memes/chest-2-icardao-ricardo-milos.gif';
import ricardo_best_gif from './metadata/memes/ricardo-is-best.webp';
import ricardo_full_turn_gif from './metadata/memes/ricardo-milos-full-turn.gif';
import ricardo_dj_gif from './metadata/memes/dj.webp';
import ricardo_blue_red_gif from './metadata/memes/ricardo-milos-dance-blue-red.gif';
import ricardo_930_gif from './metadata/memes/930-ezgif.com-optimize.gif';
import ricardo_violin_gif from './metadata/memes/ricardo-violin.gif';

import ricardo_flute_LR_gif from './metadata/memes/ricardo-milos-silly-flute.gif-ezgif-5-b9378f1561.gif';
import ricardo_flute_RL_gif from './metadata/memes/ricardo-milos-flute-long.gif';

import dancin_flute_mp3 from './metadata/sounds/dancin-aaron-smith-flute-version-COMPRESSED.mp3';

////////////////////
const landingPage = document.getElementById("landingPage");
landingPage.style.display = 'block';

const closeLanding = document.createElement('button');
closeLanding.id = "closeLandingBtn";
closeLanding.innerHTML = "ENTER ?";
landingPage.appendChild(closeLanding);

const landingBg = document.createElement('img');
landingBg.id = "landingBgImg";
landingBg.src = landingBgImg;
landingPage.appendChild(landingBg);

const landingHomeGif = document.createElement('img');
landingHomeGif.id = "landingHomeGif";
landingHomeGif.src = landing_home_gif;
landingHomeGif.style.display = 'none';
landingPage.appendChild(landingHomeGif);

const mainPage = document.getElementById("mainPage");
mainPage.style.display = 'none';

const socials = document.createElement('div');
socials.id = "socials";

const telegramBtn = document.createElement('button'); 
telegramBtn.id = "telegramBtn";
telegramBtn.innerHTML = "Telegram";
socials.appendChild(telegramBtn);

const twitterBtn = document.createElement('button'); 
twitterBtn.id = "twitterBtn";
twitterBtn.innerHTML = "Twitter";
socials.appendChild(twitterBtn);

const tiktokBtn = document.createElement('button');
tiktokBtn.id = "tiktokBtn";
tiktokBtn.innerHTML = "Tiktok";
socials.appendChild(tiktokBtn);

const chartBtn = document.createElement('button'); 
chartBtn.id = "chartBtn";
chartBtn.innerHTML = "Chart";
socials.appendChild(chartBtn);

const chartPage = function() {
  const container = document.createElement('div');
  container.id = "chartPage";
  container.style.display = 'grid';

  const dexscreener = document.createElement('button');
  dexscreener.id = "dexscreener";
  dexscreener.innerHTML = "Dexscreener";
  container.appendChild(dexscreener);
  const dextools = document.createElement('button');
  dextools.id = "dextools";
  dextools.innerHTML = "Dextools";
  container.appendChild(dextools);

  dexscreener.addEventListener('click', () => window.open("https://dexscreener.com/solana/A8UF59ApaHkoEYTb5ZYYoiLwYAEgnQQthNpgKhny8SyB", "_blank"));
  dextools.addEventListener('click', () => window.open("https://www.dextools.io/app/en/solana/pair-explorer/A8UF59ApaHkoEYTb5ZYYoiLwYAEgnQQthNpgKhny8SyB", "_blank"));

  const closeBtn = document.createElement('button');
  closeBtn.id = "chartPageClose";
  closeBtn.innerHTML = "CLOSE";
  container.appendChild(closeBtn);
  closeBtn.addEventListener('click', function() {
    container.remove();
  });

  mainPage.appendChild(container);
}


const howToBtn = document.createElement('button');
howToBtn.id = "howToBtn";
howToBtn.innerHTML = "How to buy $RICARDO ?";
socials.appendChild(howToBtn);

const buyBtn = document.createElement('button'); 
buyBtn.id = "buyBtn";
buyBtn.innerHTML = "BUY";
socials.appendChild(buyBtn);

mainPage.appendChild(socials);

const howToPage = function() {
  const container = document.createElement('div');
  container.id = "howToPage";
  container.style.display = 'flex';
  socials.style.display = 'none';
  
  const closeBtn = document.createElement('button');
  closeBtn.id = "howToClose";
  closeBtn.innerHTML = "CLOSE";
  container.appendChild(closeBtn);
  closeBtn.addEventListener('click', function() {
    socials.style.display = 'grid';
    container.remove();
  });

  const header = document.createElement('h1');
  header.innerHTML = "How to buy";

  const step1 = document.createElement('h2');
  step1.innerHTML = "Step 1: Create a Solana Wallet";
  const substep1 = document.createElement('span');
  substep1.innerHTML = "You need to install a Solana Wallet app like Phantom Wallet and create your wallet account";

  const step2 = document.createElement('h2');
  step2.innerHTML = "Step 2: Acquire some $SOL";
  const substep2 = document.createElement('span');
  substep2.innerHTML = "Buy some solana on an exchange and send it to your wallet's address";

  const step3 = document.createElement('h2');
  step3.innerHTML = "Step 3: Open jup.ag";
  const substep3 = document.createElement('span');
  substep3.innerHTML = "Click on the 'Copy' button below and open the jup.ag website, proceed to paste the token address you just copied";

  const step4 = document.createElement('h2');
  step4.innerHTML = "Step 4: Enter amount of SOL to swap and accept";
  const substep4 = document.createElement('span');
  substep4.innerHTML = "Once you have accepted the transaction your order will be processed shortly";

  container.appendChild(header);
  container.appendChild(step1);
  container.appendChild(substep1);
  container.appendChild(step2);
  container.appendChild(substep2);
  container.appendChild(step3);
  container.appendChild(substep3);
  container.appendChild(step4);
  container.appendChild(substep4);

  mainPage.appendChild(container);
}

const contractContainer = document.createElement('div');
contractContainer.id = "contractContainer";

const contract = document.createElement('span');
contract.id = "contract";
contract.innerHTML = "A8UF59ApaHkoEYTb5ZYYoiLwYAEgnQQthNpgKhny8SyB";
contractContainer.appendChild(contract);

const copyCa = document.createElement('button');
copyCa.id = "copyCaBtn";
copyCa.innerHTML = "COPY";
contractContainer.appendChild(copyCa);

const supplyText = document.createElement('span');
supplyText.id = "supplyText";
supplyText.innerHTML = "Current Supply: 1,000,000,000";
contractContainer.appendChild(supplyText);

mainPage.appendChild(contractContainer);

const scaleGifContainer = document.createElement('div');
scaleGifContainer.id = "scaleGif";
mainPage.appendChild(scaleGifContainer);

const scaleGifImgs = [
  og_flip_gif, og_flip_inverted_gif, head_flip_small_gif, head_flip_small_inverted_gif, 
  chest_dance_1_gif, chest_dance_2_gif, ricardo_best_gif, ricardo_full_turn_gif, 
  ricardo_dj_gif, ricardo_blue_red_gif, ricardo_930_gif, ricardo_violin_gif
];

const scaleGif = function() {
  scaleGifContainer.innerHTML = '';
  for (let i = 0; i < 12; i++) {
    const img = document.createElement('img');
    img.src = scaleGifImgs[i];
    scaleGifContainer.appendChild(img);
  }
}

const bgMemeContainer = document.createElement('div');
bgMemeContainer.id = "bgMemeContainer";

const lr_container = document.createElement('div');
const lr_container_content = () => {
  for (let i = 0; i < 24; i++) {
    const img = document.createElement('img');
    img.src = ricardo_flute_LR_gif;
    lr_container.appendChild(img);
  }  
}
bgMemeContainer.appendChild(lr_container);

const rl_container = document.createElement('div');
const rl_container_content = () => {
  for (let i = 0; i < 24; i++) {
    const img = document.createElement('img');
    img.src = ricardo_flute_RL_gif;
    rl_container.appendChild(img);
  }  
}
bgMemeContainer.appendChild(rl_container);

mainPage.appendChild(bgMemeContainer);

////////////////////
document.addEventListener('DOMContentLoaded', function() {
  const singleBellAudio = new Audio(singleBellMp3);
  singleBellAudio.loop = false;
  singleBellAudio.volume = 1.0;

  const footstepsAudio = new Audio(footsteps_mp3);
  footstepsAudio.loop = false;
  footstepsAudio.volume = 1.0;

  const openDoorAudio = new Audio(openDoor_mp3);
  openDoorAudio.loop = false;
  openDoorAudio.volume = 1.0;

  const dancinFluteAudio = new Audio(dancin_flute_mp3);
  dancinFluteAudio.loop = true;
  dancinFluteAudio.volume = 1.0;

  closeLanding.addEventListener('click', function() {
    const pressBell = function() {
      closeLanding.style.backgroundColor = 'var(--base)';
      closeLanding.style.border = '4px solid var(--textTran)'
      closeLanding.style.color = 'var(--textTran)';

      singleBellAudio.play();
      landingHomeGif.style.display = 'block';
      footstepsAudio.play();

      const openDoorSound = function() {
        openDoorAudio.play();
      }
      setTimeout(openDoorSound, 2300);

      const hideBtn = function() {
        closeLanding.style.display = 'none';
      }
      setTimeout(hideBtn, 690);
    }
    pressBell();

    const revealSite = function() {
      landingPage.style.display = 'none';
      mainPage.style.display = 'block';
      dancinFluteAudio.play();
      scaleGif();
      setInterval(scaleGif, 24000);
      lr_container_content();
      rl_container_content();
    }
    setTimeout(revealSite, 3820);
  });

  howToBtn.addEventListener('click', () => {
    howToPage();
  });

  // Hyper Links
  chartBtn.addEventListener('click', () => {
    chartPage();
  });


  telegramBtn.addEventListener('click', () => window.open("https://t.me/ricardocommunity", "_self"));
  twitterBtn.addEventListener('click', () => window.open("https://x.com/RICARDO_memesol", "_self"));
  tiktokBtn.addEventListener('click', () => window.open("https://www.tiktok.com/@ricardomilos_solana", "_self"));
  buyBtn.addEventListener('click', () => window.open("https://raydium.io/swap/?inputMint=sol&outputMint=A8UF59ApaHkoEYTb5ZYYoiLwYAEgnQQthNpgKhny8SyB", "_blank"));

  // Copy Contract Logic
  const textToCopy = contract.innerHTML;
  copyCa.addEventListener('click', async() => {
    try {
      // Try the modern Clipboard API first (if supported)
      await navigator.clipboard.writeText(textToCopy);
      console.log("Text copied successfully using Clipboard API");
      alert("Contract copied successfully!");
    } catch(err) {
      // If Clipboard API fails, use the legacy approach
      const textArea = document.createElement("textarea");
      textArea.value = textToCopy;
      textArea.style.position = "fixed"; // Hide element off-screen
      textArea.style.left = "-9999px";
      document.body.appendChild(textArea);
      textArea.select();
      document.execCommand("copy");
      document.body.removeChild(textArea);
      console.log("Text copied successfully using legacy approach");
      alert("Contract copied successfully!");
    }
  });
});